<template>
  <el-container
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
    "
    class="logs2"
  >
    <el-main style="flex-grow: 1; width: 100%">
      <div
        class="content_box"
        style="
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <h1 style="margin-top: 0px; font-size: 20px; text-align: center">
          {{ stype =='10'?'郑州市':'' }}职业技能培训管理服务平台学习证明       
        </h1>
        <el-table
          :show-header="false"
          :data="userData1"
          border
          style="width: 100%; margin-top: 30px"
        >
          <el-table-column prop="key" width="70"> </el-table-column>
          <el-table-column prop="value"> </el-table-column>
        </el-table>
        <el-table
          :show-header="false"
          :data="userData"
          border
          style="width: 100%; margin-top: 0px; border-top: 0"
        >
          <el-table-column prop="key1" width="70"> </el-table-column>
          <el-table-column prop="value1"> </el-table-column>
          <el-table-column prop="key2" width="70"> </el-table-column>
          <el-table-column prop="value2"> </el-table-column>
        </el-table>
        <el-tabs type="card" stretch style="width: 100%; margin-top: 30px">
          <el-tab-pane label="影像记录">
            <el-table
              :data="leftData"
              border
              style="
                width: 100%;
                margin-bottom: 30px;
                border-top-width: 0;
                flex-grow: 1;
                margin-top: 0px;
                border-top-width: 1px;
              "
            >
              <el-table-column
                prop="captureTime"
                label="拍摄时间"
                min-width="150s"
              >
              </el-table-column>
              <el-table-column
                prop="courseName"
                label="培训课程"
                min-width="133"
              >
              </el-table-column>
              <el-table-column
                prop="videoName"
                label="视频名称"
                min-width="133"
              >
              </el-table-column>
              <el-table-column prop="result" label="检测结果" min-width="70">
              </el-table-column>
              <el-table-column label="抓拍照片" min-width="70">
                <template slot-scope="scope">
                  <div style="display: flex">
                    <img
                      v-if="scope.row.imagePath"
                      :src="scope.row.imagePath"
                      style="width: 40px; height: 50px; margin-right: 10px"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              small
              layout="prev, pager, next"
              :total="parseInt(apiData.total)"
              :current-page="apiData.current"
              @current-change="getLeftData"
            >
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="学习追溯">
            <el-table
              :data="rightData"
              border
              style="
                width: 100%;
                margin-bottom: 30px;
                border-top-width: 0;
                flex-grow: 1;
                margin-top: 0px;
                border-top-width: 1px;
              "
            >
              <el-table-column
                type="index"
                label="序号"
                :index="indexMethod"
                align="center"
              />
              <el-table-column
                prop="timeInterval"
                label="学习时段"
                min-width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime }} 至 {{ scope.row.createTime }}
                </template>
              </el-table-column>
              <el-table-column
                prop="catalogName"
                label="所学课程"
                min-width="133"
              >
              </el-table-column>
              <el-table-column
                prop="courseName"
                label="所学章节"
                min-width="133"
              >
              </el-table-column>
              <el-table-column label="学习时长" min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.learningLime }}
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              small
              layout="prev, pager, next"
              :total="parseInt(apiData1.total)"
              :current-page="apiData1.current"
              @current-change="getRightData"
            >
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-main>
  </el-container>
</template>
<script>
export default {
  name: "zhengzhouH5/logs2",
  data() {
    return {
      userData: [],
      userData1: [],
      leftData: [],
      rightData: [],
      info: {},
      apiData: {
        total: 0,
        current: 1,
      },
      pageNum: 1,
      apiData1: {
        total: 0,
        current: 1,
      },
      pageNum1: 1,
      pageSize: 10,
      stype: "",
    };
  },
  mounted() {
    this.getApi();
    this.getLeftData();
    this.getRightData();
  },
  methods: {
    // 序号
    indexMethod(index) {
      return (this.pageNum1 - 1) * 10 + index + 1;
    },
    getApi() {
      this.$post("/biz/heNan/queryH5/queryUserInfo", {
        json: this.$route.query.json,
      })
        .then((res) => {
          this.info = res.data;
          if (this.info.certificateType == "10") {
            this.stype = this.info.certificateType;
           this.userData1 = [
              {
                key: "姓名",
                value: this.info.userName,
              },
              {
                key: "所属培训平台",
                value: this.info.platformName,
              },
            ];
            this.userData = [
              {
                key1: "身份证号",
                value1: this.info.idCard,
                key2: "联系电话",
                value2: this.info.mobile,
              },
              {
                key1: "是否多平台培训",
                value1: this.info.multiPlatform,
                key2: "本年度培训次数（次）",
                value2: this.info.trainingNum,
              },
              {
                key1: "培训工种",
                value1: this.info.occupationName,
                key2: "培训等级",
                value2: this.info.trainTypeLevel,
              },
              {
                key1: "抓拍监管",
                value1: this.info.userFaceState,
                key2: "人员类别",
                value2: this.info.projectCrowd,
              },
              {
                key1: "学习形式",
                value1: this.info.studyType,
                key2: "所在班级",
                value2: this.info.projectName,
              },
              {
                key1: "所属主管部门",
                value1: this.info.competentAuthorities,
                key2: "学习状态",
                value2: this.info.completeState,
              },
              {
                key1: "班级总学时（学时）",
                value1: this.info.projectLessonNum,
                key2: "班级总时长（分钟）",
                value2: this.info.projectDuration,
              },
              {
                key1: "获得学时（学时）",
                value1: this.info.studyLessonNum,
                key2: "学习总时长（分钟）",
                value2: this.info.studyDuration,
              },
            ];
          } else {
            this.userData1 = [
              {
                key: "姓名",
                value: this.info.userName,
              },
              {
                key: "联系电话",
                value: this.info.mobile,
              },
              {
                key: "身份证号",
                value: this.info.idCard,
              },
            ];
            this.userData = [
              {
                key1: "所属培训平台",
                value1: this.info.platformName,
                key2: "所在班级",
                value2: this.info.projectName,
              },
              {
                key1: "学习状态",
                value1: this.info.completeState,
                key2: "人员类别",
                value2: this.info.projectCrowd,
              },
              {
                key1: "培训工种",
                value1: this.info.occupationName,
                key2: "培训等级",
                value2: this.info.trainTypeLevel,
              },
              {
                key1: "学习形式",
                value1: this.info.studyType,
                key2: "抓拍监管",
                value2: this.info.userFaceState,
              },
              {
                key1: "班级总学时（学时）",
                value1: this.info.projectLessonNum,
                key2: "班级总时长（分钟）",
                value2: this.info.projectDuration,
              },
              {
                key1: "获得学时（学时）",
                value1: this.info.studyLessonNum,
                key2: "学习总时长（分钟）",
                value2: this.info.studyDuration,
              },
            ];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 影像记录
    getLeftData(pageNum = 1) {
      this.pageNum = pageNum;
      this.$post("/biz/heNan/queryH5/queryImageList", {
        pageNum: this.pageNum,
        pageSize: 10,
        json: this.$route.query.json,
      })
        .then((res) => {
          this.leftData = res.data.list;
          this.apiData.total = res.data.total;
        })
        .catch((err) => {
          this.getLeftData();
        });
    },
    //学习追溯
    getRightData(pageNum = 1) {
      this.pageNum1 = pageNum;
      this.$post("/biz/heNan/queryH5/queryTrackList", {
        json: this.$route.query.json,
        pageNum: this.pageNum1,
        pageSize: 10,
      })
        .then((res) => {
          this.rightData = res.data.list;
          this.apiData1.total = res.data.total;
        })
        .catch((err) => {
          this.getRightData();
        });
    },
  },
};
</script>

<style lang="less">
.logs2 {
  .el-tabs__header {
    margin: 0;
  }
}
.table_h5 {
  td {
    border: 1px solid #ccc;
  }
}
</style>>